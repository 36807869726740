.app-settings-client-type {
  font-family: Inter, sans-serif;
}

::v-deep .table.b-table > thead > tr > [aria-sort="ascending"],
::v-deep .table.b-table > tfoot > tr > [aria-sort="ascending"] {
  background-size: 20px;
  background-position: right calc(2rem / 6) center !important;
  background-image: url("../../../assets/icons/icon-arrow-down.svg") !important;
}

::v-deep .table.b-table > thead > tr > [aria-sort="descending"],
::v-deep .table.b-table > tfoot > tr > [aria-sort="descending"] {
  background-size: 20px;
  background-position: right calc(2rem / 6) center !important;
  background-image: url("../../../assets/icons/icon-arrow-down.svg") !important;
}

.vip-status {
  padding: 12px 48px;
}

.x-horizontal-divider {
  position: relative;
  padding-bottom: 2rem;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: calc(100% + 6rem);
    margin-left: -3rem;
    height: 6px;
    background-color: var(--gray-100);
  }
}
